* {
  margin: 0;
  padding: 0;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  text-align: center;
}

.heading {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.link {
  text-decoration: none;
  color: #000;
}

.link:hover {
  text-decoration: underline;
}
